import { debounce } from "../helper/functions";

class HeaderWrapper extends HTMLElement {
  constructor() {
    super();
    this.scrollThreshold = 100; // Set scroll distance threshold
    this.search = this.querySelector(`#search-modal`);
    this.scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    this.template = this.getAttribute('template');
    this.invertText = this.getAttribute('text-invert');

    this.announcement = document.querySelector('announcement-slider');
    this.header = document.querySelector('header');

    //LOGO | DESKTOP NAV MENU | BACKGROUND
    this.invertedElements = {
      logo: this.querySelector('#header-logo'),
      menu: this.querySelectorAll('.header-desktop-menu'),
      background: this.querySelector('#header-background')
    }

    this.invertedTemplates = ['index']
  }

  connectedCallback() {
      //document.querySelector('#MainContent').firstElementChild.style.marginTop = this.announcement.clientHeight + 'px';
      if(this.template == "index"){
        this.initScrollListener();
        this.initHoverListener();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let scrollUp = false;
        if(scrollTop > this.scrollPosition) {
          //when scrolling down
          scrollUp = false;
        } else {
          //when scrolling up.
          scrollUp = true;
        }
        if (scrollTop > this.scrollThreshold) {
          console.log('scroll down main')
          this.classList.add('scrolled');
          this.scrolledDownHandler(scrollUp);
        } else {
          this.scrollTopHandler();
          this.classList.remove('scrolled');
        }
        if(this.invertText == "false") {
          this.invertedElements.logo.classList.remove('invert-0');
          this.invertedElements.logo.classList.add('invert');
          //Menu invert (black & white text only)
          this.invertedElements.menu.forEach(menu => {
            menu.classList.add('text-brand-primary-100')
             menu.classList.remove('text-white');
          });
        } else {
          this.invertedElements.logo.classList.add('invert-0');
          this.invertedElements.logo.classList.remove('invert');
      
        //Menu invert (black & white text only)
        this.invertedElements.menu.forEach(menu => {
          menu.classList.remove('text-brand-primary-100');
          menu.classList.add('text-white');
        });
        }
      } else {
        this.scrolledDownHandler();
      }
  }

  disconnectedCallback() {
    this.removeScrollListener();
  }

  initScrollListener() {
    this.handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let scrollUp = false;
      if(scrollTop > this.scrollPosition) {
        //when scrolling down
        scrollUp = false;
      } else {
        //when scrolling up.
        scrollUp = true;
      }
      if (scrollTop > this.scrollThreshold) {
        this.classList.add('scrolled');
        console.log('scrolled down')
        this.scrolledDownHandler(scrollUp);
          //if there are announcements
          if(this.announcement) {
            this.announcement.clientHeight;
            console.log(this.announcement.clientHeight);
            //show the annoucement bar
            this.header.style.top = `-${this.announcement.clientHeight}px`
            
          }
      } else {
        this.scrollTopHandler();
        this.classList.remove('scrolled');
    //if there are announcements
    if(this.announcement) {
      this.announcement.clientHeight;
      console.log(this.announcement.clientHeight);
      //show the annoucement bar
      this.header.style.top = `0px`
      
    }
      }
      this.scrollPosition = scrollTop;
    };
    window.addEventListener('scroll', this.handleScroll);
  }

  initHoverListener() {
    this.addEventListener('mouseenter', this.scrolledDownHandler);
    this.addEventListener('mouseleave', this.scrollTopHandler);
  }

  removeScrollListener() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  scrolledDownHandler(scrollDelta) {
    //trigger hover state
    //Logo invert (black & white only)
    this.invertedElements.logo.classList.remove('invert-0');
    this.invertedElements.logo.classList.add('invert');
    //Menu invert (black & white text only)
    this.invertedElements.menu.forEach(menu => {
      menu.classList.add('text-brand-primary-100')
      menu.classList.remove('text-white');
    });
    //Background transparency
    this.invertedElements.background.classList.add('opacity-100');
    this.invertedElements.background.classList.remove('opacity-0');
  }

  scrollTopHandler(scrollDelta) {
    //trigger transparenet state
    //Logo invert (black & white only)
    if(this.template == "index"){
      console.log(this.invertText)
      if(this.invertText == "true") {
        this.invertedElements.logo.classList.add('invert-0');
        this.invertedElements.logo.classList.remove('invert');
      
        //Menu invert (black & white text only)
        this.invertedElements.menu.forEach(menu => {
          menu.classList.remove('text-brand-primary-100');
          menu.classList.add('text-white');
        });
      }
      //Background transparency
      this.invertedElements.background.classList.remove('opacity-100');
      this.invertedElements.background.classList.add('opacity-0');
    }
  }
}

customElements.define('header-wrapper', HeaderWrapper);

class HamburgerMenu extends HTMLElement {
  constructor() {
    super();

    this.burger = this.querySelector('.burger');
    this.close = this.querySelector('.close');

    this.addEventListener('click', this.toggle);

  }

  toggle() {
    this.burger.classList.toggle('opacity-0');
    this.close.classList.toggle('opacity-0');
  }

}

customElements.define('hamburger-menu', HamburgerMenu);

class Search extends HTMLElement {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      query: ''
    };
    this.dom = {
      form: document.querySelector(".js-search-form"),
      bar: document.querySelector(".js-search-form-bar"),
      search: document.querySelector(".js-search-input"),
      close: document.querySelector(".js-close-search"),
      header: document.querySelector("header-wrapper")
    };
    this.addEventListener('click', this.toggle.bind(this));
    this.dom.close.addEventListener('click', this.toggle.bind(this));
    this.dom.search.addEventListener('input', debounce(() => this.handleSearch.bind(this),250));
    this.dom.form.addEventListener('submit', this.handleSubmit.bind(this));
  }
  toggle() {
    const { state, dom } = this;
    state.isOpen = !state.isOpen;
    state.query = "";

    dom.bar.classList.add("translate-y-[-100vh]");
    dom.header.classList.remove("inverted-nav");
    dom.search.value = state.query;

    if (state.isOpen) {
      dom.bar.classList.remove("translate-y-[-100vh]");
      dom.header.classList.add("inverted-nav");
      dom.search.focus();
    } else {
      dom.search.blur();
    }
  }
  handleSearch() {
    const { state, dom } = this;
    const { search } = dom;
    const searchTerm = search.value.trim();

    return (state.query = searchTerm);
  }
  handleSubmit(e) {
    e.preventDefault();

    const { state, dom } = this;
    state.query = `/search?q=${state.query}`;
    dom.form.action = state.query;

    return (dom.form.submit());
  }
}

customElements.define('search-toggle', Search);
